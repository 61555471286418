.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
.hideme_small {
  display: none;
}
@media (min-width: 61.875rem) {
  .hideme_small {
    display: block;
  }
}
.showme_print_hideme {
  display: none;
}
::selection {
  background: #a39e34;
  color: #363636;
}
* {
  -webkit-tap-highlight-color: rgba(196, 190, 70, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.3125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #363636;
  font-family: "Glacial Indifference Regular", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.3125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.25rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.5625rem + 0.9375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.5625rem;
  color: #0D0D0D;
  display: inline-block;
  font-family: "Glacial Indifference Regular", sans-serif;
  font-weight: 400;
  line-height: 1;
  margin: 1.35em 0;
  position: relative;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.5625rem + 0.9375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.5rem;
  }
}
h1:after,
.style_h1:after {
  background: #C4BE46;
  bottom: -25%;
  content: "";
  left: -15%;
  position: absolute;
  right: -15%;
  top: -25%;
  z-index: -1;
}
h2,
.style_h2 {
  letter-spacing: calc((1.375rem + 0.5 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.375rem;
  color: #363636;
  font-family: "Glacial Indifference Regular", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1.05em;
  padding-top: 1.5em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.375rem + 0.5 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.875rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.125rem + 0.5 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.125rem;
  color: #363636;
  font-family: "Glacial Indifference Regular", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 2.1em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.125rem + 0.5 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.625rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #363636;
  font-family: "Glacial Indifference Regular", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #363636;
  font-family: "Glacial Indifference Bold", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.75rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.75rem;
  color: #363636;
  font-family: "Glacial Indifference Bold", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #0D0D0D;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
.main a {
  font-family: "Glacial Indifference Bold", sans-serif;
  font-weight: 700;
}
a::after {
  background-color: #41BDF5;
  bottom: 0.125rem;
  content: "";
  height: 0.0625rem;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  -webkit-transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
  transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
  z-index: -1;
}
a:active,
a.active {
  outline: 0;
  text-decoration: underline;
}
a:focus {
  outline: 0.1875rem solid rgba(196, 190, 70, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover::after {
  left: 50%;
  opacity: 0;
  right: 50%;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:after {
  display: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.hideme_print:after {
  display: none;
}
a.touch_link {
  color: #363636;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #C4BE46;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #363636;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(196, 190, 70, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.ie_eleven .main a,
.ie_eleven .footer a {
  text-decoration: underline;
}
.ie_eleven .main a:after,
.ie_eleven .footer a:after {
  display: none;
}
.ie_eleven .main a:hover,
.ie_eleven .footer a:hover,
.ie_eleven .main a:focus,
.ie_eleven .footer a:focus,
.ie_eleven .main a.acive,
.ie_eleven .footer a.acive {
  text-decoration: none !important;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.125rem solid #dcdcdc;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #dcdcdc;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #F5F5F5;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #C4BE46;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
ul h2 {
  margin-bottom: 0.375em;
}
.defined_list dl {
  border-bottom: 0.125rem solid #dcdcdc;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.125rem solid #dcdcdc;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.125rem solid #dcdcdc;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.9375rem + 0.3125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.9375rem + 0.3125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.25rem;
  }
}
label {
  cursor: pointer;
}
back input[type="number"] {
  -moz-appearance: textfield;
}
back input[type="number"]::-webkit-inner-spin-button,
back input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #363636;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #767676;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #767676;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #767676;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #767676;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #C4BE46;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #C4BE46;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.1875rem;
  color: #363636;
  font-family: "Glacial Indifference Bold", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.125rem solid #dcdcdc;
  border-radius: 0;
  box-shadow: 0 0 0 #dcdcdc inset;
  color: #363636;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #C4BE46;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
.input:focus,
.textarea:focus {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 2.75rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
  position: relative;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #C4BE46;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
.control input:focus ~ .indicator {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
}
.control .indicator {
  background: #fff;
  border: 0.125rem solid #dcdcdc;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #363636;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.125rem solid #dcdcdc;
  border-radius: 0;
  box-shadow: 0 0 0.375rem #dcdcdc inset;
  color: #363636;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #363636;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #C4BE46;
}
.select.error.focus {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
.select.focus {
  border-color: #C4BE46;
  box-shadow: 0 0 0.375rem #C4BE46 inset, 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
  color: #363636;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #363636;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.5rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.5rem;
  }
}
select option {
  min-height: 2.5rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.5rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #363636;
}
select:focus::-ms-value {
  background: none;
  color: #363636;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(196, 190, 70, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(196, 190, 70, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(196, 190, 70, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(196, 190, 70, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .header .header_links_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
  .header_links_wrapper.main_links {
    display: none;
  }
  .header_links_wrapper a {
    color: #0D0D0D;
    text-decoration: none;
  }
  .header_links_wrapper a:hover,
  .header_links_wrapper a:focus,
  .header_links_wrapper a.acitve {
    text-decoration: underline;
  }
  .header_links_wrapper a:hover .icon,
  .header_links_wrapper a:focus .icon,
  .header_links_wrapper a.acitve .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .header_links_wrapper .header_link > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    min-height: 2.75rem;
    min-width: 2.75rem;
  }
  .header_links_wrapper .header_link .icon {
    margin-right: 1.2em;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .header_links_wrapper .header_link.call .icon {
    height: 1.6875rem;
    width: 1.875rem;
  }
  .header_links_wrapper .header_link.mail .icon {
    height: 1.0625rem;
    width: 1.875rem;
  }
  .header_links_wrapper .header_link .text {
    display: none;
  }
  .link_with_two_icon {
    padding: 1.5em;
    -webkit-box-align: center;
            align-items: center;
    background: #F5F5F5;
    color: #363636;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
  }
  .link_with_two_icon:hover .cross_icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .link_with_two_icon:hover .text_wrapper .icon svg {
    -webkit-transform: rotate(90deg) scale(1.1);
            transform: rotate(90deg) scale(1.1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .link_with_two_icon:after {
    display: none;
  }
  .link_with_two_icon .cross_icon {
    height: 3.5625rem;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    width: 3.5625rem;
  }
  .link_with_two_icon .text_wrapper {
    margin-top: 1.5625rem;
    text-align: center;
    width: 100%;
  }
  .link_with_two_icon .text_wrapper .title {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 700;
    margin-bottom: 0;
  }
  .link_with_two_icon .text_wrapper p {
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 400;
    margin-bottom: 1.25rem;
    width: 100%;
  }
  .link_with_two_icon .text_wrapper .icon {
    height: 1rem;
    width: 1.25rem;
  }
  .link_with_two_icon .text_wrapper .icon svg {
    height: 1rem;
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    width: 1.25rem;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact > span {
    min-width: 1.5625rem;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .address a {
    color: #363636;
    position: relative;
    text-decoration: none;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .address a::after {
    background-color: #41BDF5;
    bottom: 0.125rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
    transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
    z-index: -1;
  }
  .address a:active,
  .address a.active {
    outline: 0;
    text-decoration: underline;
  }
  .address a:hover::after,
  .address a:focus::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  .aside_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .aside_wrapper::after {
    background-color: #41BDF5;
    bottom: 0;
    content: "";
    left: -3.125rem;
    position: absolute;
    right: -1000%;
    top: 0;
    z-index: -2;
  }
  .aside .sidebar {
    margin-bottom: 1.5em;
  }
  .aside .sidebar .aside_text_wrapper {
    margin-bottom: 1.5em;
    color: #0D0D0D;
  }
  .aside .sidebar .opening_times .title {
    padding-top: 2.1em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_wrapper {
    content: "";
    position: absolute;
    right: 2.5rem;
    top: -2rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    z-index: 100;
  }
  .author_wrapper .author_ver {
    display: inline-block;
    fill: #fff;
    height: 4.6875rem;
    line-height: 4.6875rem;
    width: 0.5625rem;
  }
  .background_image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .button {
    border-radius: 0;
    padding: 0 1.5em;
    background: #fff;
    border: 0.125rem solid #C4BE46;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .button .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
    outline: 0;
    background: #ebf8fe;
    border-color: #41BDF5;
    color: #0D0D0D;
  }
  .button:hover {
    background: #ebf8fe;
    border-color: #41BDF5;
    color: #0D0D0D;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.375rem #41BDF5 inset;
    background: #ebf8fe;
    border: 0.125rem solid #41BDF5;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button.bottom_spacing {
    margin-bottom: 0.75em;
  }
  .white_button {
    border-radius: 0;
    padding: 0 1.5em;
    background: #fff;
    border: 0.125rem solid #fff;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .white_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .white_button .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .white_button .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .white_button .icon + .text {
    padding-left: 0.5em;
  }
  .white_button:focus {
    box-shadow: 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
    outline: 0;
    background: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
    color: #0D0D0D;
  }
  .white_button:hover {
    background: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
    color: #0D0D0D;
  }
  .white_button:active,
  .white_button.active {
    box-shadow: 0 0 0.375rem #fff inset;
    background: #fff;
    border: 0.125rem solid #fff;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .white_button:active > span,
  .white_button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .white_button:active .icon,
  .white_button.active .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .white_button:active .text,
  .white_button.active .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .white_button:active .icon + .text,
  .white_button.active .icon + .text {
    padding-left: 0.5em;
  }
  .white_button.bottom_spacing {
    margin-bottom: 0.75em;
  }
  .green_button {
    background: #C4BE46;
    border: 0.125rem solid #C4BE46;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0;
    min-width: 13rem;
    padding: 0 1.5em;
  }
  .green_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .green_button .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .green_button .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .green_button .icon + .text {
    padding-left: 0.5em;
  }
  .green_button:focus {
    box-shadow: 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
    outline: 0;
    background: #d0cc6d;
    border-color: #d0cc6d;
    color: #0D0D0D;
  }
  .green_button:hover {
    background: #d0cc6d;
    border-color: #d0cc6d;
    color: #0D0D0D;
  }
  .green_button:active,
  .green_button.active {
    box-shadow: 0 0 0.375rem #d0cc6d inset;
    background: #d0cc6d;
    border: 0.125rem solid #d0cc6d;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .green_button:active > span,
  .green_button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .green_button:active .icon,
  .green_button.active .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .green_button:active .text,
  .green_button.active .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .green_button:active .icon + .text,
  .green_button.active .icon + .text {
    padding-left: 0.5em;
  }
  .green_button.bottom_spacing {
    margin: 0.75em 0;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.75em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #C4BE46;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #C4BE46;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .steuernews_call_to_action {
    background: #F5F5F5;
    border-radius: 0;
    display: inline-block;
    margin: 0 0 1.5em;
    padding: 0.75em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .company_logo {
    max-width: 16.875rem;
    padding-bottom: 0.75em;
    padding-top: 2.1em;
    background: #fff;
    display: block;
    margin: auto;
    position: relative;
  }
  .company_logo:after {
    background: #41BDF5;
    content: "";
    height: 0.9375rem;
    left: -1000%;
    position: absolute;
    right: -1000%;
    top: 0rem;
    z-index: 99;
  }
  .company_logo:hover:after {
    left: -1000%;
    opacity: 1;
    right: -1000%;
  }
  .company_logo::before {
    background: #fff;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: -1000%;
    top: 0rem;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
  .content_bg_image_container {
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    z-index: 0;
  }
  .content_bg_image_container > .container {
    width: 100%;
  }
  .content_bg_image_container:hover.show_overlay::after {
    background: rgba(196, 190, 70, 0.3);
    -webkit-transition: background 300ms ease-in;
    transition: background 300ms ease-in;
  }
  .content_bg_image_container a {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    -webkit-box-align: center;
            align-items: center;
    color: #363636;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
  }
  .content_bg_image_container a:hover .cross_icon {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .content_bg_image_container a:hover .text_wrapper .icon svg {
    -webkit-transform: rotate(90deg) scale(1.1);
            transform: rotate(90deg) scale(1.1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .content_bg_image_container a:after {
    display: none;
  }
  .content_bg_image_container a .cross_icon {
    height: 3.5625rem;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    width: 3.5625rem;
  }
  .content_bg_image_container a .text_wrapper {
    text-align: center;
  }
  .content_bg_image_container a .text_wrapper .title {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    padding-top: 0.75em;
  }
  .content_bg_image_container a .text_wrapper p {
    margin-bottom: 0;
  }
  .content_bg_image_container a .text_wrapper .icon {
    height: 1rem;
    width: 1.25rem;
  }
  .content_bg_image_container a .text_wrapper .icon svg {
    height: 1rem;
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    width: 1.25rem;
  }
  .content_bg_image_container.center {
    background-position-x: center;
  }
  .content_bg_image_container.left {
    background-position-x: left;
  }
  .content_bg_image_container.right {
    background-position-x: right;
  }
  .content_bg_image_container.middle {
    background-position-y: center;
  }
  .content_bg_image_container.top {
    background-position-y: top;
  }
  .content_bg_image_container.bottom {
    background-position-y: bottom;
  }
  .content_bg_image_container.show_overlay::after {
    background: rgba(196, 190, 70, 0.2);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms ease-in;
    transition: background 300ms ease-in;
    z-index: -1;
  }
  .content_bg_image_container.fixed {
    background-attachment: fixed;
  }
  html.ios .content_bg_image_container.fixed,
  html.mobile .content_bg_image_container.fixed {
    background-attachment: scroll;
  }
  .content_bg_image_container.full_width {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #F5F5F5;
    border-top: 0.125rem solid #dcdcdc;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 9000;
  }
  .cookie_banner p {
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
    width: 100%;
  }
  .cookie_banner .close {
    cursor: pointer;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    margin-bottom: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.5rem;
  }
  .cookie_banner .close .text {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0 0.3em;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-right: -3.125rem;
    padding: 3.75em 0;
    position: relative;
    width: 100%;
  }
  .dropdown_menu > ul li {
    margin-right: -3.125rem;
    padding-right: 3.125rem;
    position: relative;
  }
  .dropdown_menu > ul li:hover > ul {
    visibility: visible;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
    padding-right: -3.125rem;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #363636;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 2.2;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a::after {
    border-bottom: 1rem solid transparent;
    border-right: 0rem solid #C4BE46;
    border-top: 1rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: auto;
    opacity: 0;
    position: absolute;
    right: -3.0625rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    width: 0;
  }
  .dropdown_menu > ul > li > a::before {
    background-color: #C4BE46;
    bottom: 0.5rem;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
    transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
  }
  .dropdown_menu > ul > li > a.active:after {
    background: transparent;
    border-right: 1.625rem solid #41BDF5;
    left: auto;
    opacity: 1;
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    z-index: auto;
  }
  .dropdown_menu > ul > li > a:hover,
  .dropdown_menu > ul > li > a:focus {
    color: #363636;
  }
  .dropdown_menu > ul > li > a:hover:after,
  .dropdown_menu > ul > li > a:focus:after {
    background: transparent;
    border-right: 1.625rem solid #C4BE46;
    left: auto;
    opacity: 1;
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    z-index: auto;
  }
  .dropdown_menu > ul > li > a:hover:before,
  .dropdown_menu > ul > li > a:focus:before {
    left: 0;
    opacity: 1;
    right: 0;
  }
  .dropdown_menu > ul > li ul {
    background: #C4BE46;
    min-width: 12.5rem;
    padding: 0.75em 0;
    position: absolute;
    right: 0.0625rem;
    top: 50%;
    -webkit-transform: translateX(100%) translateY(-50%);
            transform: translateX(100%) translateY(-50%);
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul::after {
    border-bottom: 1rem solid transparent;
    border-right: 1.625rem solid #C4BE46;
    border-top: 1rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-100%) translateY(-50%);
            transform: translateX(-100%) translateY(-50%);
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    width: 0;
  }
  .dropdown_menu > ul > li ul:target {
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #0D0D0D;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    line-height: 2;
    padding: 0 0.75em;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a .text {
    position: relative;
  }
  .dropdown_menu > ul > li ul a .text::before {
    background-color: #fff;
    bottom: 0.5rem;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
    transition: left 300ms ease-in, right 300ms ease-in, opacity 300ms ease-in;
  }
  .dropdown_menu > ul > li ul a:hover,
  .dropdown_menu > ul > li ul a.active {
    color: #0D0D0D;
  }
  .dropdown_menu > ul > li ul a:hover .text:before,
  .dropdown_menu > ul > li ul a.active .text:before {
    left: 0;
    opacity: 1;
    right: 0;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a:after {
    display: none;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.75em;
  }
  .footer .background_image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 calc(-100vw + 100%);
    max-height: 9.375rem;
    min-width: 115vw;
    position: relative;
  }
  .footer .background_image::after {
    border-bottom: 3.4375rem solid #F5F5F5;
    border-left: 2.1875rem solid transparent;
    border-right: 2.1875rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    right: 0;
    -webkit-transform: translateX(-55%);
            transform: translateX(-55%);
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    width: 0;
  }
  .footer .address_link_wrapper {
    padding: 2.7em 0 2.25em 0;
    position: relative;
  }
  .footer .address_link_wrapper::after {
    background: #41BDF5;
    bottom: 0rem;
    content: "";
    height: 0.9375rem;
    left: -1000%;
    position: absolute;
    right: -1000%;
  }
  .footer .address_link_wrapper::before {
    background: #F5F5F5;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: -1000%;
    top: 0rem;
    z-index: -1;
  }
  .footer .address .company_name {
    padding-top: 0;
  }
  .footer .address .location_link {
    display: -webkit-box;
    display: flex;
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 700;
  }
  .footer .address .location_link:hover,
  .footer .address .location_link:focus,
  .footer .address .location_link.active {
    text-decoration: underline;
  }
  .footer .address .location_link .icon {
    fill: #C4BE46;
    height: 0.875rem;
    margin-right: 0.3em;
    width: 0.625rem;
  }
  .footer .address .location_link:after {
    display: none;
  }
  .footer .related_links {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-top: 1.8em;
    position: relative;
    z-index: 1;
  }
  .footer .related_links ul li {
    display: inline;
  }
  .footer .related_links ul li a {
    color: #363636;
    text-decoration: none !important;
  }
  .footer .related_links ul li a:after {
    display: none;
  }
  .footer .related_links ul li a:hover,
  .footer .related_links ul li a:focus,
  .footer .related_links ul li a.active {
    text-decoration: underline !important;
  }
  .footer .related_links ul li::after {
    content: " | ";
    margin: 0.5em;
  }
  .footer .related_links ul li:last-child:after {
    display: none;
  }
  .form_disclaimer {
    background: #F5F5F5;
    border-radius: 0;
    color: #363636;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #C4BE46;
  }
  .file .file_name {
    background: #fff;
    border: 0.125rem solid #dcdcdc;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.375rem #dcdcdc inset;
    color: #363636;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.5rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #fff;
    border: 0.125rem solid #C4BE46;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .file .file_button .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
    background: #ebf8fe;
    border-color: #41BDF5;
    color: #0D0D0D;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #ebf8fe;
    border-color: #41BDF5;
    color: #0D0D0D;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.375rem #41BDF5 inset;
    background: #ebf8fe;
    border: 0.125rem solid #41BDF5;
    color: #363636;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.5rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #363636;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-family: "Glacial Indifference Bold", sans-serif;
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .header {
    position: relative;
  }
  .right_section {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-left: -0.0625rem;
    position: relative;
    z-index: -1;
  }
  .navigation_outer_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: end;
            justify-content: flex-end;
    position: relative;
  }
  .navigation_outer_wrapper:after {
    background-color: #F5F5F5;
    bottom: 0;
    content: "";
    left: -100%;
    position: absolute;
    right: -100%;
    top: 0;
    z-index: -1;
  }
  .navigation_outer_wrapper .navigation_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    position: relative;
  }
  .image_wrapper {
    position: relative;
  }
  .header_background {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 calc(-100vw + 100%);
    min-height: 11.25rem;
    min-width: 100vw;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .language_select ul {
    display: -webkit-box;
    display: flex;
  }
  .language_select ul .language_links {
    letter-spacing: calc((1.25rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.25rem;
    -webkit-box-align: center;
            align-items: center;
    color: #0D0D0D;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 2.75rem;
  }
  .language_select ul .language_links.active {
    color: #000000;
  }
  .language_select ul .language_links.active:after {
    background: #C4BE46;
    bottom: -0.25rem;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .language_select ul .language_links:hover {
    background: #d0cc6d;
    -webkit-transition: background 300ms ease-in;
    transition: background 300ms ease-in;
  }
  .language_select ul .language_links:hover.active {
    background: #d0cc6d;
    -webkit-transition: background 300ms ease-in;
    transition: background 300ms ease-in;
  }
  .language_select_large {
    display: none;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  .body_wrapper {
    max-width: 160rem;
    overflow: hidden;
  }
  .main {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
  }
  .main .container {
    width: 100%;
  }
  .startpage .main:after {
    display: none;
  }
  .main .content_wrapper {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    position: relative;
    z-index: 10;
  }
  .intro_description {
    margin-bottom: 1.5em;
    max-width: 56.4375rem;
  }
  .startpage .sitetitle_wrapper {
    position: relative;
    text-align: center;
  }
  .startpage .sitetitle_wrapper::after {
    background-color: #C4BE46;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: -100%;
    top: 0;
    z-index: -1;
  }
  .startpage .sitetitle_wrapper h1 {
    background: #C4BE46;
    line-height: 1.2;
    margin: 0;
    padding: 0.9em 0.45em;
  }
  .startpage .sitetitle_wrapper h1:after {
    display: none;
  }
  .startpage .sitetitle_wrapper .sitetitle {
    letter-spacing: calc((1.5625rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.5625rem;
    display: block;
    line-height: 1.6;
  }
  .startpage .sitetitle_wrapper .additonal_title {
    letter-spacing: calc((1.125rem + 0.75 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    display: block;
    line-height: 1.6;
  }
  .startpage .sitetitle_wrapper .title_addition_bottom {
    letter-spacing: calc((1rem + 0.5625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
  }
  .startpage .sitetitle_wrapper .title_addition_top {
    letter-spacing: calc((1.5625rem + 0.9375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.5625rem;
    color: #0D0D0D;
    display: inline-block;
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    position: relative;
  }
  .startpage .startpage_content {
    margin-top: 2.25em;
  }
  .startpage .startpage_content .text_startpage_wrapper,
  .startpage .startpage_content .opening_times_startpage {
    margin: 0 0 1.5em 0;
    text-align: center;
  }
  .startpage .startpage_content .text_startpage {
    margin-bottom: 1.5em;
  }
  .startpage .startpage_content .opening_times_startpage {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .startpage .startpage_content .opening_times_startpage .title {
    margin-bottom: 0.6em;
    padding-top: 0;
  }
  .startpage .startpage_content .opening_times_startpage .opening_times {
    text-align: center;
  }
  .startpage .startpage_content .opening_times_startpage .opening_times .entries {
    display: block;
    margin-bottom: 0.45em;
  }
  .startpage .startpage_content .opening_times_startpage .opening_times .entry {
    line-height: 1.8;
    margin: 0;
  }
  .startpage .startpage_content .opening_times_startpage .opening_times .clock {
    display: none;
  }
  .startpage .startpage_content .opening_times_startpage .opening_times strong {
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 400;
  }
  .infolist_download {
    margin-bottom: 1.5em;
  }
  .impressum .content_wrapper h3 {
    padding-top: 0;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    background: #C4BE46;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 3rem;
    z-index: 10;
  }
  .toggle_offcanvas_menu label::after {
    background: #C4BE46;
    bottom: -0.25rem;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    right: 0;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.625rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #0D0D0D;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.125rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: -0.125rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(54, 54, 54, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #F5F5F5;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((1.125rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
    color: #363636;
    display: block;
    font-weight: 700;
    padding: 0.6875rem 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:after {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus {
    background: #C4BE46;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1rem;
    color: #363636;
    display: block;
    line-height: 1.75;
    padding: 0.375em 1.5em 0.375em 3em;
    position: relative;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a::after {
    background: transparent;
    border-bottom: 0.75rem solid transparent;
    border-left: 0rem solid #41BDF5;
    border-top: 0.75rem solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    right: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    width: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active::after,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover::after,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus::after {
    border-left: 1.375rem solid #41BDF5;
    opacity: 1;
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.125rem solid #dcdcdc;
    border-top: 0.125rem solid #dcdcdc;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(54, 54, 54, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #C4BE46;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #F5F5F5;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(245, 245, 245, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #C4BE46;
    border-radius: 0;
    color: #0D0D0D;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #ebf8fe;
    border: 0.125rem solid #41BDF5;
    box-shadow: 0 0 0 0.375rem rgba(196, 190, 70, 0.2);
    color: #0D0D0D;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #ebf8fe;
    border: 0.125rem solid #41BDF5;
    color: #0D0D0D;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 3rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #F5F5F5;
    border-radius: 0;
    color: #363636;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #dcdcdc;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .social_media_share {
    padding-bottom: 2.25em;
    padding-top: 2.7em;
    position: relative;
  }
  .social_media_share:after {
    background-color: #C4BE46;
    bottom: 0;
    content: "";
    left: -3.125rem;
    position: absolute;
    right: -1000%;
    top: 0;
    z-index: -1;
  }
  .social_media_share:before {
    border-left: 1.1875rem solid transparent;
    border-right: 1.1875rem solid transparent;
    border-top: 1.75rem solid #fff;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: -0.0625rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: border 300ms ease-in;
    transition: border 300ms ease-in;
    width: 0;
  }
  .social_media_share .title {
    color: #0D0D0D;
    margin-bottom: 0;
    padding-top: 0;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0 0;
    padding: 0;
  }
  .social_media_share li {
    line-height: 0;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .social_media_share a {
    color: #0D0D0D;
    cursor: pointer;
    display: block;
    padding-right: 1.0625rem;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a:hover {
    text-decoration: underline;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    color: #0D0D0D;
    font-weight: 400;
    padding-left: 1.0625rem;
    padding-right: 0.75em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #C4BE46;
    border-radius: 50%;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a.facebook .icon,
  .social_media_share a.twitter .icon {
    border-radius: 0;
  }
  .social_media_share a.whatsapp {
    display: none;
  }
  .tablet .social_media_share a.whatsapp,
  .mobile .social_media_share a.whatsapp {
    display: block;
  }
  .teaser_overview {
    margin-bottom: 1.95em;
    margin-top: 2.25em;
  }
  .teaser {
    padding-bottom: 1.2em;
    padding-top: 1.95em;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-align: center;
  }
  .teaser:hover,
  .teaser:focus {
    opacity: 0.9;
  }
  .teaser:hover .icon svg,
  .teaser:focus .icon svg {
    fill: #0D0D0D;
    -webkit-transform: rotate(90deg) scale(2);
            transform: rotate(90deg) scale(2);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
  }
  .teaser.teaser_image {
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 12.5rem;
    position: relative;
    z-index: -1;
  }
  .teaser a {
    text-decoration: none !important;
  }
  .teaser a:after {
    display: none;
  }
  .teaser .title h2 {
    letter-spacing: calc((1.5625rem + 1.875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.5625rem;
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0.75em;
    padding: 0;
    text-transform: uppercase;
  }
  .teaser .text_wrapper {
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 400;
    max-width: 20rem;
    margin: 0 auto;
  }
  .teaser .text_wrapper p {
    margin-bottom: 1.5em;
  }
  .teaser .icon {
    height: 1rem;
    width: 1.25rem;
  }
  .teaser .icon svg {
    height: 1rem;
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    width: 1.25rem;
  }
  .teaser.green {
    background: #C4BE46;
  }
  .teaser.blue {
    background: #41BDF5;
  }
  .teaser.grey {
    background: #D5D5D5;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  .checkbox .tooltip_wrapper {
    position: static;
  }
  [role="tooltip"] {
    background: #F5F5F5;
    border: 0.125rem solid #dcdcdc;
    border-radius: 0;
    bottom: 100%;
    color: #363636;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #F5F5F5;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #dcdcdc;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .header .header_links_wrapper {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .header .header_links_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper.main_links {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-bottom: 1.25rem;
    padding: 5.1875rem 0 1.875rem 0;
  }
  .startpage .header_links_wrapper::after {
    background-color: #D5D5D5;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: -3.125rem;
    top: 0;
    z-index: -1;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link {
    margin-bottom: 1.5625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link > span {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link .icon {
    margin-left: 1.2em;
    margin-right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link.call .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
  .header_links_wrapper .header_link.call .icon svg {
    width: 3.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .header_links_wrapper .header_link.call .icon {
    height: 3.125rem;
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link.mail .icon {
    height: 1.375rem;
    width: 2.8125rem;
  }
  .header_links_wrapper .header_link.mail .icon svg {
    width: 5rem;
  }
}
@media screen and (min-width: 75rem) {
  .header_links_wrapper .header_link.mail .icon {
    height: 1.75rem;
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_links_wrapper .header_link .text {
    display: -webkit-box;
    display: flex;
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  .link_with_two_icon {
    padding: calc(1.5em + 0.9000000000000004 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .link_with_two_icon {
    padding: 2.4em;
  }
}
@media screen and (min-width: 93.75rem) {
  .link_with_two_icon {
    height: 18.75rem;
    margin-right: -1.25rem;
    margin-top: -3.125rem;
    width: 17.625rem;
  }
}
@media screen and (min-width: 35rem) {
  .aside_wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .aside_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 35rem) {
  .aside {
    display: -webkit-box;
    display: flex;
    flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 50%;
  }
}
@media screen and (min-width: 61.875rem) {
  .aside {
    flex-basis: auto;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-ordinal-group: 2;
            order: 1;
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .aside .sidebar {
    margin-left: 1.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .aside .sidebar {
    margin-left: 0;
  }
}
@media screen and (min-width: 20rem) {
  .aside .sidebar .aside_text_wrapper {
    margin-bottom: calc(1.5em + 1.3499999999999996 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .aside .sidebar .aside_text_wrapper {
    margin-bottom: 2.85em;
  }
}
@media screen and (min-width: 61.875rem) {
  .author_wrapper {
    left: 0.625rem;
    right: auto;
    top: 58%;
    -webkit-transform: translateY(-58%);
            transform: translateY(-58%);
  }
}
@media screen and (min-width: 75rem) {
  .author_wrapper {
    top: 60%;
    -webkit-transform: translateY(-60%);
            transform: translateY(-60%);
  }
}
@media screen and (min-width: 93.75rem) {
  .author_wrapper {
    left: 0.625rem;
    top: 63%;
    -webkit-transform: translateY(-63%);
            transform: translateY(-63%);
  }
}
@media screen and (min-width: 35rem) {
  .author_wrapper .author_ver {
    fill: #a0a0a0;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .white_button > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .white_button > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .white_button:active > span,
  .white_button.active > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .white_button:active > span,
  .white_button.active > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .green_button > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .green_button > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .green_button:active > span,
  .green_button.active > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .green_button:active > span,
  .green_button.active > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    max-width: calc(16.875rem + 6.6875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 23.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    padding-bottom: calc(0.75em + 2.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    padding-bottom: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    padding-top: calc(2.0999999999999996em + 1.6500000000000004 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    padding-top: 3.75em;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo {
    margin-left: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo {
    margin-left: auto;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo:after {
    right: -0.3125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo:after {
    right: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo:after {
    height: 1.1875rem;
    right: -3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo:hover:after {
    right: -0.3125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo:hover:after {
    right: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo:hover:after {
    height: 1.1875rem;
    right: -3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo::before {
    right: -0.3125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo::before {
    right: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .company_logo::before {
    height: 1.1875rem;
    right: -3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo.company_logo_1 {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo.company_logo_2 {
    display: block;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo.company_logo_2 .img_container {
    margin-left: -3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .content_bg_image_container a {
    padding-bottom: calc(1.5em + 3.5999999999999996 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_bg_image_container a {
    padding-bottom: 5.1em;
  }
}
@media screen and (min-width: 20rem) {
  .content_bg_image_container a {
    padding-top: calc(1.5em + 2.6999999999999993 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_bg_image_container a {
    padding-top: 4.2em;
  }
}
@media screen and (min-width: 160rem) {
  .content_bg_image_container.full_width {
    margin-left: calc(50% -  160rem / 2);
    margin-right: calc(50% -  160rem / 2);
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    text-align: left;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner .close {
    margin: 0 0.75em;
  }
}
@media screen and (min-width: 20rem) {
  .cookie_banner .close > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.25rem;
    position: relative;
  }
  .dropdown_menu::after {
    background-color: #F5F5F5;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
    top: 0;
    z-index: -1;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 61.875rem) {
  .dropdown_menu::after {
    right: -3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .footer .background_image {
    margin: 0 calc(-100vw + 100%);
    max-height: none;
    min-height: 100%;
    min-width: 100vw;
  }
}
@media screen and (min-width: 35rem) {
  .footer .background_image::after {
    border-bottom: 2.1875rem solid transparent;
    border-right: 3.4375rem solid #F5F5F5;
    border-top: 2.1875rem solid transparent;
    left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@media screen and (min-width: 35rem) {
  .footer .address_link_wrapper {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .footer .address_link_wrapper::after {
    left: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .footer .address_link_wrapper::after {
    height: 1.1875rem;
    left: -3.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .footer .address_link_wrapper::before {
    left: calc(-0.9375rem + -2.1875 * (-100vw  + 20rem) / (-73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .footer .address_link_wrapper::before {
    left: -3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 2.75rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .right_section {
    margin-top: -1.25rem;
    max-height: 37.5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .right_section {
    margin-top: 0;
    max-height: none;
    z-index: 100;
  }
}
@media screen and (min-width: 35rem) {
  .navigation_outer_wrapper {
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .navigation_outer_wrapper {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .navigation_outer_wrapper {
    display: block;
    margin: 0;
  }
  .navigation_outer_wrapper:after {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .navigation_outer_wrapper .navigation_wrapper {
    display: block;
  }
}
@media screen and (min-width: 35rem) {
  .header_background {
    min-height: 18.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_background {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-left: 0;
    margin-right: calc(-75vw + 100%);
    min-width: auto;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 20rem) {
  .language_select ul .language_links {
    font-size: calc(1.25rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .language_select ul .language_links {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select ul .language_links {
    background: #fff;
    -webkit-transition: background 300ms ease-in;
    transition: background 300ms ease-in;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select ul .language_links.active {
    background: #C4BE46;
  }
  .language_select ul .language_links.active:after {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select_small {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .language_select_large {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }
}
@media screen and (min-width: 61.875rem) {
  .main:after {
    background: #fff;
    bottom: 0;
    content: "";
    left: -1000%;
    position: absolute;
    right: 0;
    top: -1.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .main .content_wrapper {
    padding-bottom: calc(1.5em + 4.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .main .content_wrapper {
    padding-bottom: 6em;
  }
}
@media screen and (min-width: 20rem) {
  .main .content_wrapper {
    padding-top: calc(1.5em + 1.3499999999999996 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .main .content_wrapper {
    padding-top: 2.85em;
  }
}
@media screen and (min-width: 35rem) {
  .main .content_wrapper {
    max-width: 90%;
  }
}
@media screen and (min-width: 93.75rem) {
  .main .content_wrapper {
    max-width: 56.4375rem;
  }
}
@media screen and (min-width: 35rem) {
  .main .content_wrapper.no_margin_right {
    max-width: 95%;
  }
}
@media screen and (min-width: 20rem) {
  .intro_description {
    margin-bottom: calc(1.5em + 1.9499999999999997 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .intro_description {
    margin-bottom: 3.45em;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .sitetitle_wrapper {
    bottom: 1.25rem;
    left: 1.25rem;
    content: "";
    position: absolute;
    text-align: left;
    z-index: 100;
  }
  .startpage .sitetitle_wrapper:after {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .startpage .sitetitle_wrapper h1 {
    padding: 0.9em 1.05em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .sitetitle_wrapper .sitetitle {
    font-size: calc(1.5625rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .sitetitle_wrapper .sitetitle {
    font-size: 2.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .sitetitle_wrapper .additonal_title {
    font-size: calc(1.125rem + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .sitetitle_wrapper .additonal_title {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .sitetitle_wrapper .title_addition_bottom {
    font-size: calc(1rem + 0.5625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .sitetitle_wrapper .title_addition_bottom {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .sitetitle_wrapper .title_addition_top {
    font-size: calc(1.5625rem + 0.9375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .sitetitle_wrapper .title_addition_top {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .startpage_content {
    margin-top: calc(2.25em + 2.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .startpage_content {
    margin-top: 4.5em;
  }
}
@media screen and (min-width: 35rem) {
  .startpage .startpage_content .text_startpage_wrapper,
  .startpage .startpage_content .opening_times_startpage {
    text-align: left;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .startpage_content .text_startpage_wrapper,
  .startpage .startpage_content .opening_times_startpage {
    margin: 0 0 2.25em 3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .startpage_content .opening_times_startpage {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .startpage_content .opening_times_startpage .opening_times {
    text-align: right;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.6875rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.6875rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .offcanvas_menu {
    padding-top: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(1.125rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .social_media_share {
    padding-bottom: calc(2.25em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .social_media_share {
    padding-bottom: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .social_media_share {
    padding-top: calc(2.7em + 0.2999999999999998 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .social_media_share {
    padding-top: 3em;
  }
}
@media screen and (min-width: 35rem) {
  .social_media_share {
    display: -webkit-box;
    display: flex;
    flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 50%;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share {
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    -webkit-box-ordinal-group: 3;
            order: 2;
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .social_media_share:after {
    right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share:after {
    right: -1000%;
  }
}
@media screen and (min-width: 61.875rem) {
  .social_media_share:before {
    border-bottom: 1.1875rem solid transparent;
    border-left: 1.75rem solid #fff;
    border-top: 1.1875rem solid transparent;
    left: -3.125rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview {
    margin-bottom: calc(1.9500000000000002em + 2.8500000000000005 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview {
    margin-bottom: 4.8em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview {
    margin-top: calc(2.25em + 0.9000000000000004 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview {
    margin-top: 3.15em;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_overview .row > [class*="span"]:nth-child(even) .teaser {
    margin-bottom: 1.25rem;
    margin-top: -1.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .teaser_overview .row > [class*="span"]:nth-child(even) .teaser {
    margin: 0;
  }
  .teaser_overview .row > [class*="span"]:nth-child(2) .teaser,
  .teaser_overview .row > [class*="span"]:nth-child(5) .teaser,
  .teaser_overview .row > [class*="span"]:nth-child(8) .teaser {
    margin-bottom: 1.25rem;
    margin-top: -1.25rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .row > [class*="span"]:nth-child(even) .teaser {
    margin-bottom: 1.25rem;
    margin-top: -1.25rem;
  }
  .teaser_overview .row > [class*="span"]:nth-child(5) .teaser {
    margin: 0;
  }
}
@media screen and (min-width: 20rem) {
  .teaser {
    padding-bottom: calc(1.2000000000000002em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser {
    padding-bottom: 2.7em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser {
    padding-top: calc(1.9500000000000002em + 2.55 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser {
    padding-top: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  .teaser .title h2 {
    font-size: calc(1.5625rem + 1.875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .title h2 {
    font-size: 3.4375rem;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 20rem !important;
  }
  .company_logo img {
    display: block;
  }
  .company_logo.company_logo_2 {
    display: none;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  .showme_print_hideme {
    display: block !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .teaser a {
    font-family: "Glacial Indifference Regular", sans-serif;
    font-weight: 400;
    text-decoration: none;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  body {
    font-size: 1rem !important;
  }
  h1,
  .style_h1 {
    font-size: 1.5625rem !important;
    margin-bottom: 1.25rem !important;
    width: 100% !important;
  }
  h1 span,
  .style_h1 span {
    font-size: 1.125rem !important;
    margin: 0.625rem 0 !important;
    display: block;
  }
  h1:after,
  .style_h1:after {
    display: none !important;
  }
  h1 .sitetitle,
  .style_h1 .sitetitle {
    font-size: 1.5625rem !important;
  }
  main {
    padding-top: 0 !important;
  }
  h2,
  .style_h2 {
    font-size: 1.375rem !important;
    margin-bottom: 0.625rem !important;
    margin-top: 1.875rem !important;
    padding: 0 !important;
    text-align: left !important;
    text-transform: none !important;
  }
  h3,
  .style_h3 {
    font-size: 1.375rem !important;
    margin-bottom: 0.625rem !important;
    margin-top: 1.25rem !important;
    padding: 0 !important;
    text-transform: none !important;
  }
  h4 {
    font-size: 1.125rem !important;
    margin-bottom: 1.25rem !important;
    padding: 0 !important;
    text-transform: none !important;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  .open_street_map button {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    display: none;
  }
  .open_street_map .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    list-style: none !important;
    margin: 0.75em 0;
    padding: 0;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_entity .hide_image_author {
    display: none !important;
  }
  body {
    display: block;
    font-size: 1.125rem;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.125rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
